<template>
    <div>
        <Hero />
    </div>
</template>

<script>
    export default {
        components: {
            Hero: () => import('../components/sections/Hero'),
        },
        name: "Home"
    }
</script>

<style scoped>

</style>
